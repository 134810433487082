import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1fe8341a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "card-view"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  class: "cover-adress global-display global-a-items"
};
var _hoisted_5 = {
  class: "text-show"
};
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import emitter from '@/api/event.js';
export default {
  __name: 'my-nft',
  props: {
    mynftlists: {
      type: Array,
      required: true
    },
    tip: {
      type: String,
      required: true
    }
  },
  setup: function setup(__props) {
    var $router = useRouter(); // 这是路由跳转的

    var $route = useRoute();

    function juMp(_id, _address) {
      // 获取跳转的路由：如果用户点击了详情页的推荐就向详情页触发响应
      if ($route.path === '/nftdetail') {
        emitter.emit('details-response', {
          _id: _id
        });
        $router.replace({
          path: $route.path,
          query: Object.assign({}, $route.query, {
            _id: _id,
            _address: _address
          })
        });
      } else {
        $router.push({
          path: '/nftdetail',
          query: {
            _id: _id,
            _address: _address
          }
        });
      }
    }

    return function (_ctx, _cache) {
      var _component_van_empty = _resolveComponent("van-empty");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.mynftlists, function (item, index) {
        return _openBlock(), _createElementBlock("div", {
          class: "item-card fadeIn",
          onClick: function onClick($event) {
            return juMp(item.tokenId, item.address.address);
          }
        }, [_createElementVNode("img", {
          src: item.metadata.image,
          class: "cover-image"
        }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, "#" + _toDisplayString(item.tokenId) + "_" + _toDisplayString(item.metadata.name), 1)])], 8, _hoisted_2);
      }), 256))]), __props.mynftlists.length <= 0 ? (_openBlock(), _createBlock(_component_van_empty, {
        key: 0,
        image: "https://diancan-1252107261.cos.accelerate.myqcloud.com/lvyou/icon/kong-zhuang-tai.png",
        "image-size": "80",
        description: __props.tip
      }, null, 8, ["description"])) : _createCommentVNode("", true)], 64);
    };
  }
};